<template>
  <CommonPages id="home" classs="home">
    <div class="cd-fixed-bg cd-bg-1">
      <h1>Empowering Your Digital World</h1>
      <p class="content-common content-type-1">
        Ramnil delivers innovative software solutions globally, from tools and games to web and AI
        products. We enhance digital experiences, connecting users worldwide.
      </p>

      <div class="btn-common bgn-type-1">
        Contact us
      </div>
    </div>

    <div class="cd-scrolling-bg ">
      <div class="scrolling-head-row">
        <div class="scrolling-head-name">
          Discover Our Services
        </div>
      </div>
      <div class="service-intro-row pc_padding">
        <div v-for="(data,i) in service" :key="`service_${i}`"
          :class="`service-intro-item service-intro-item-${i+1}`">
          <img :src="data.icon" :alt="data.title">
          <div class="service-intro-item-name">
            {{ data.title }}
          </div>
          <div class="service-intro-item-desc" v-html=" data.desc" />
        </div>
      </div>
    </div>

    <div class="cd-fixed-bg cd-bg-2">
      <div class="scrolling-head-row cd-2">
        <div class="scrolling-head-name">
          Discover Our Services
        </div>
      </div>
      <div class="carousel-container" @mouseleave="hoveredIndex = -1">
        <div class="carousel-track">
          <div v-for="(item,index) in displayItems" :key="index" class="carousel-item"
            :class="{ hovered: hoveredIndex === index }" @mouseenter="hoveredIndex = index">
            <img
              :src="(hoveredIndex === index && deviceType != 'mobile') ? item.hoverImg : item.img"
              alt="">
          </div>
        </div>
      </div>
    </div>

    <div id="about" class="cd-scrolling-bg ">
      <div class="scrolling-head-row">
        <div class="scrolling-head-name">
          Meet Our Team
        </div>
      </div>
      <div class="carousel-container pc_padding">
        <div class="carousel-track-team">
          <div v-for="(item,index) in displayTeams" :key="index" class="carousel-item-team"
            :class="`team-${item.bg}`">
            <img :src="item.avatar" :alt="item.name">
            <div class="team-info">
              <div class="team-name-title">
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="title" :class="`title-${item.bg}`">
                  {{ item.title }}
                </div>
              </div>

              <div class="desc" v-html="item.desc" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="contact" class="cd-fixed-bg cd-bg-3 ">
      <div class="pc_padding contact-row">
        <div class="company-summary">
          <img src="@/assets/logo.svg" alt="">
          Ramnil delivers innovative software solutions globally, from tools and games to web and AI
          products. We enhance digital experiences, connecting users worldwide.
        </div>
        <div class="company-addr">
          <div class="footer-head">
            Address
          </div>
          <div class="footer-content">
            Rm 03 24/F HO KING COML CTR 2-16 FA YUEN ST Mongkok, HongKong, China
          </div>
        </div>
        <div class="company-contact">
          <div class="footer-head">
            Contact
          </div>
          <div class="footer-content">
            Email: support@ramnil.com
          </div>
          <div class="footer-content">
            Phone: +852 68792726
          </div>
        </div>
      </div>
    </div>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import CommonPages from '@/pages/components/page_common.vue'
import '@/css/home.scss';

export default {
  name: 'Home',
  components: {
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      service: [
        { icon: require('@/assets/icon1.svg'), title: 'Innovative Android Solutions', desc: 'We create cutting-edge Android applications tailored to meet diverse user needs, ensuring seamless performance and engaging user experiences across all devices.' },
        { icon: require('@/assets/icon2.svg'), title: 'Maximizing Digital Reach', desc: 'Our strategic traffic operations optimize user engagement and boost visibility, driving growth and expanding your digital footprint effectively.' },
        { icon: require('@/assets/icon3.svg'), title: 'Collaborative Success', desc: 'We partner with creators to publish and promote content, leveraging our expertise to reach wider audiences and achieve shared success.' },
      ],
      hoveredIndex: -1,
      partner: [
        { img: require('@/assets/google.png'), hoverImg: require('@/assets/google-h.png') },
        { img: require('@/assets/applovin.png'), hoverImg: require('@/assets/applovin-h.png') },
        { img: require('@/assets/facebook.png'), hoverImg: require('@/assets/facebook-h.png') },
        { img: require('@/assets/tencent.png'), hoverImg: require('@/assets/tencent-h.png') },
        { img: require('@/assets/lazada.png'), hoverImg: require('@/assets/lazada-h.png') },
        { img: require('@/assets/bigo.png'), hoverImg: require('@/assets/bigo-h.png') },
      ],
      team: [
        {
          avatar: require('@/assets/avatar1.png'),
          name: 'William',
          bg: 1,
          title: 'CEO',
          desc: 'As the CEO of Ramnil, I drive our strategic vision and foster innovation across all departments. My goal is to empower our team to create impactful digital solutions that transform the way people interact with technology.'
        },
        {
          avatar: require('@/assets/avatar2.png'),
          name: 'Robert',
          bg: 2,
          title: 'CTO',
          desc: 'As the CTO, I lead our tech team in developing state-of-the-art software products. My passion lies in exploring emerging technologies and ensuring our solutions remain at the forefront of the industry.'
        },
        {
          avatar: require('@/assets/avatar3.png'),
          name: 'John',
          bg: 3,
          title: 'Developer',
          desc: 'As the Lead Android Developer, I specialize in creating intuitive and high-performance mobile applications. I am dedicated to delivering seamless user experiences that delight and engage our customers.'
        },
        {
          avatar: require('@/assets/avatar4.png'),
          name: 'Jennifer',
          bg: 4,
          title: 'Product Manager',
          desc: 'As a Product Manager at Ramnil, I bridge the gap between innovation and user needs. I oversee the product lifecycle from concept to launch, ensuring our solutions are user-centric and market-ready. My focus is on delivering products that exceed expectations and drive business success.'
        },
        {
          avatar: require('@/assets/avatar5.png'),
          name: 'Linda',
          bg: 5,
          title: 'Marketing',
          desc: 'As the Head of Marketing, I craft compelling campaigns that resonate with our global audience. I focus on building brand awareness and driving user engagement through innovative and data-driven strategies.'
        },
      ]
    };
  },
  computed: {
    displayItems () {
      // 双倍数据实现无缝滚动
      if (this.deviceType == 'mobile') {
        return this.partner
      }
      return [...this.partner, ...this.partner]
    },
    displayTeams () {
      if (this.deviceType != 'mobile') {
        return this.team
      }
      return [...this.team, ...this.team]
    },
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  methods: {

  },
};
</script>
