<template>
  <footer>
    <div class="corner-module ">
      Copright @ ramnil.com. All right resiverd.
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FootModule',
};
</script>
