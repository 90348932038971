import { mapGetters } from 'vuex'
import '@/css/common.scss'

export default {
	computed: {
		...mapGetters({
			host: 'host',
			showDebug: 'showDebug',
			deviceType: 'deviceType',
			domainConfig: 'domainConfig',
		}),
	},
}
