<template>
  <div class="head-bar-container" @click.self="closeMenu">
    <div class="head-bar-row pc_padding">
      <div class="head-bar-name-row cursor" @click="gohome">
        <div class="head-bar-name-icon">
          <img src="@/assets/logo.svg" alt="">
        </div>
      </div>
      <div v-if="deviceType != 'mobile'" class="head-nav-row">
        <a href="#about">About us</a>
        <a href="#contact">Contact us</a>
      </div>
      <div v-if="deviceType == 'mobile'" class="head-bar-hamber-row cursor"
        @click="toggleOpenState">
        <img src="@/assets/default/menu.svg" alt="">
      </div>
    </div>

    <div class="menu" :class="{ 'menu-open': isOpen }">
      <ul>
        <li @click="goto('#about')">
          <a>About us</a>
          <div class="arrow" />
        </li>
        <li @click="goto('#contact')">
          <a>Contact us</a>
          <div class="arrow" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters(["deviceType"]),
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    gohome () {
      window.location.href = '/'
    },
    toggleOpenState () {
      this.isOpen = !this.isOpen;
    },
    handleClickOutside (event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    closeMenu () {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
    goto (where) {
      console.log(this.$route.path);
      this.isOpen = !this.isOpen;
      window.location.href = where
    },
  },
}
</script>
